﻿@import '_mixins.scss';

.search-items-filters-container {
    margin-bottom: 30px;
}

.items-list-container {

}


.items-list {
    margin-bottom: 30px;
}

.items-list-background {
    padding: 15px 8px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0px 6px 5.74px 1.26px rgba(0, 0, 0, 0.1);
}

.items-list-header {
    .pagination-info {
        align-items: flex-end;
    }

    margin-bottom: 25px;
}

.list-item {
    &.link:hover {
        cursor: pointer;
    }

    &.list-item-empty {
        border: none;
        font-size: 16px;
    }

    padding: 10px;
    padding-right: 15px;
    @include border-type-1;
    margin-bottom: 10px;
    position: relative;

    .item-menu-container {
        color: inherit;
    }

    ion-icon {
        font-size: 24px;
        min-width: 24px;
        margin-right: 5px;
    }

    .list-item-header {
        display: flex;
        flex-direction: row;
        font-size: 14px;
        margin-bottom: 11px;

        .time {
            margin-left: 33px;
        }

        .application-type {
            font-weight: normal;
            width: 200px;
            padding: 0 10px;
            text-align: center;
        }

        .list-item-name,
        .list-item-status,
        .list-item-links,
        .list-item-type,
        .additional-info {
            display: flex;
            align-items: center;
        }

        .list-item-name {
            min-width: 215px;
            font-weight: bold;
        }

        .list-item-status {
            display: flex;
            /*width: 250px;*/
            padding: 0 15px;

            &.status-approved {
            }

            &.status-not-approved {
                color: $main_red;

                ion-icon {
                    color: $main_red;
                }
            }

            &.status-sent {
                color: $main_blue;
            }

            &.status-notsent {
                color: $main_red;
            }

            &.status-scheduled {
                color: $main_blue;
            }

            &.status-passed {
                color: inherit;
            }

            &.status-theory-passed {
                color: $main_blue;
            }
        }

        .list-item-links {
            margin-left: 50px;
            flex-wrap: wrap;

            .list-item-link {
                margin-bottom: 5px;
                margin-right: 10px;


                a {
                    color: $main_blue;
                }
            }
        }

        .list-item-type {
            &.type-theory {
                color: $main_blue;
            }

            &.type-porfolio {
                color: inherit;
            }
        }

        .additional-info-items {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin-right: 50px;

            .additional-info-item {
                display: inline-flex;
                padding: 0 15px;
            }
        }

        .additional-info {
            margin-left: auto;

            .accent,
            .accent-bad {
                color: $main_red;
                font-weight: bold;
            }

            .accent-good {
                color: $main_blue;
                font-weight: bold;
            }

            .results-link {
                margin-right: 200px;
                padding: 0 10px;
                color: $main-text-color;
                cursor: pointer;

                .accent {
                    font-weight: normal;
                    color: $link_color;
                }
            }
        }
    }

    .list-item-content {
        font-size: 14px;
        margin-bottom: 20px;

        .content-item:not(:last-child) {
            margin-bottom: 10px;
        }

        .list-item-content-title {
            margin-bottom: 15px;
        }

        .list-item-content-description {
            color: $text-blue;
        }
    }

    .list-item-footer {
        display: flex;
        flex-direction: row;
        font-size: 14px;


        ion-icon {
            font-size: 16px;
        }

        .list-item-footer-item {
            &.date-period {
                min-width: 180px;
            }

            display: flex;
            align-items: flex-end;

            &:not(:last-child) {
                margin-right: 45px;
            }
        }
    }
}

.items-list-pagination {
    margin-bottom: 10px;
    display: flex;
    align-items: center;

    &.to-right {
        font-size: 14px;
        display: flex;
        justify-content: flex-end;

        a {
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .pagination-item {
        display: flex;
        font-weight: 500;
        position: relative;

        &:not(:last-child) {
            margin-right: 5px;
        }

        &.active {
            .pagination-link {
                display: flex;
                width: 28px;
                height: 28px;
                background-color: $main-red;
                color: #fff;
                border-radius: 50%;
                justify-content: center;
                align-items: center;
            }
        }

        .pagination-link {
            position: relative;
            color: inherit;
        }
    }

    display: flex;
    font-size: 14px;
}

.tooltip-menu {
    background-color: #fff;
    padding: 20px;
    @include border-1;
    @include box-shadow;
    border-color: $light-grey;
    width: 172px;

    .item-menu-action {
        display: flex;
        text-decoration: none;
        color: $main-text-color;
        &:not(:last-child) {
            margin-bottom: 20px;
        }
        font-size: 12px;

        &:hover {
            font-weight: bold;
            text-decoration: underline;
        }
    }
}

/*npa list*/
.npa-list-item-container {
    padding-right: 100px;
}
.npa-list-item {
    display:flex;
    .item-menu-container {
        cursor: pointer;
        margin-left:auto;
    }
}

