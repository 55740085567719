@import '_mixins.scss';

.table-filter {
    margin-bottom: 40px;
}
.table {
    width: 100%;


    th, td {
        padding: 20px 10px 12px 10px;
        line-height: 1.167;
        text-align: left;
    }

    th {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: bold;
    }

    .download-file {
        color: $main-text-color;
    }

    .item-menu-container {
        font-size: 24px;
        color: $main-text-color;
    }

    .actions {
        vertical-align: middle;
    }
}

.table-container {
    .pagination-container {
        margin-top: 30px;
        padding-bottom: 15px;
        padding-right: 20px;
    }

    &.with-absolute-title {
        padding-top: 40px;
    }

    &.table-container-with-padding-bottom {
        padding-bottom: 50px;
        background-color: #f6f6f6;
    }
}

.table-two-halves {
    @include box-shadow-right;

    &.with-absolute-title {
        margin-top: 42px;
    }

    th {
        &.first-half {
            background-color: #d1d1dd;
        }

        &.second-half {
            background-color: rgba(0, 127, 188, 0.37);
        }
    }

    td {
        border-bottom: 1px solid #e5e5e7;

        &.first-half {
            background-color: #f6f6f6;
        }

        &.second-half {
            background-color: rgba(0, 127, 188, 0.1);
        }
    }
}

.absolute-title-container {
    position: relative;
    .title-block {
        position:absolute;
        top: -42px;
        left: 0;
        width: 230px;
        font-weight: normal;
        text-transform: none;
        font-size: 16px;
        &.blue {
            background-color: $main_blue;
        }
        &.red {
            background-color: $main-red;
        }
    }
}

.table-container {
    @include box-shadow-right;
    &.no-shadow {
        box-shadow: none;
    }
}
.table-admin {
    thead {
        box-shadow: 0px 3px 3px 0px rgba(133, 138, 148, 0.23);
    }

    th, td {
        vertical-align: middle;
        &:first-child {
            padding-left: 15px;
        }
    }

    th {
        font-size: 12px;
        color: $main-text-color;
        padding-top: 15px;
        background-color: #c2c3cb;
    }

    td {
        background-color: #f6f6f6;
        font-size: 14px;
        color: $main-text-color;
        border-bottom: 1px solid #e5e5e7;
        padding-top: 12px;
        padding-bottom: 12px;
        &.actions {
            position: relative;
            .item-menu-container {
                font-size: 20px;
                position: absolute;
                top: 25%;
                right: 50%;
            }
        }
    }
}

.violation-table {
    border-collapse: separate;
    border-spacing: 0 8px;
    font-size: 14px;
    font-family: Montserrat, sans-serif;
    color: $main-text-color;
    font-weight: 400;

    td {
        vertical-align: middle;
    }

    .violation-type {
        padding-right: 45px;

        &.accent {
            font-weight: bold;
        }
    }

    .violation-cnt {
        font-size: 16px;
        display: flex;
        width: 127px;
        height: 20px;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        color: #fff;

        &.accent {
            font-weight: bold;
            color: $main-text-color;
        }
    }
}

.table {
    &.table-answer {
        border-collapse: separate;
        border-spacing: 0 20px;
        height: 1px;

        th {
            font-size: 14px;
            text-transform: none;
            padding-bottom: 0;
            padding-top: 0;
        }

        td {
            vertical-align: top;
            padding: 0 5px;
            height: 100%;

            &:first-child {
                padding-left: 0;
            }

            .checkbox-container {
                margin-left: 10px;

                .big-checkbox {
                    display: flex;

                    label {
                        &:before {
                            top: 3px;
                            bottom: auto;
                            padding-left: 0;
                            width: 30px;
                        }
                    }
                }
            }

            .item-menu-container {
                font-size: 18px;
                cursor: pointer;
            }
        }

        .question-text {
            height: 100%;
        }
    }
}

.question-number {
    display: flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: $exam-result-red;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
}

.question-text {
    border:1px solid #7d7f98;
    border-radius: 5px;
    padding: 10px;
}


.vertal {
vertical-align: top !important;
}