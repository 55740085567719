.treeview-menu {
    display: flex;
    flex-direction: column;

    &.no-padding {
        .treeview-item {
            padding-left: 0;
        }
    }

    .treeview-item {
        display: flex;
        flex-direction: column;
        width: 100%;
        color: $main-text-color;
        cursor: pointer;
        min-height: 6px;
        position: relative;
        user-select: none;

        .item-menu-container {
            font-size: 18px;
        }

        &:not(.open) > .treeview-item {
            display: none;
        }

        &.has-children {
            & > .treeview-item-inner:before {
                content: '';
                display: flex;
                position: absolute;
                left: 5px;
                width: 11px;
                top: 13px;
                height: 6px;
                /*background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='11px' height='6px'%3E%3Cpath fill-rule='evenodd' fill='rgb(62, 64, 90)' d='M-0.000,-0.000 L11.000,-0.000 L5.000,6.000 L-0.000,-0.000 Z'/%3E%3C/svg%3E");*/
                background-image: url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 xmlns:xlink=%27http://www.w3.org/1999/xlink%27 width=%2711px%27 height=%276px%27%3E%3Cpath fill-rule=%27evenodd%27 fill=%27rgb%2862, 64, 90%29%27 d=%27M-0.000,-0.000 L11.000,-0.000 L5.000,6.000 L-0.000,-0.000 Z%27/%3E%3C/svg%3E");
                background-position: 0 0;
                background-repeat: no-repeat;
                background-size: 9px auto;
                transform: rotate(-90deg);
            }
        }

        .treeview-item-inner {
            position: relative;
            display: flex;
            padding: 5px;
            padding-left: 20px;
            border: 1px solid $main-text-color;
            margin-bottom: 15px;
            border-radius: 5px;
            align-items: center;

            &.current {
                background-color: #e5e5e7;
                border: 1px solid #0F75B9;
            }

            & > a {
                color: $main-text-color;
                font-size: 14px;
                word-break: break-word;
            }

            &:hover {
                & > .treeview-item-text {
                    text-decoration: underline;
                }
            }
        }

        .treeview-item-text {
            display: flex;
            flex-grow: 1;
            color: $main-text-color;
            margin-bottom: 10px;
            word-break: break-all;
        }

        .treeview-item {
            padding-left: 15px;
        }



        .item-menu-container {
            margin-left: auto;
            padding-left: 10px
        }

        &.open {
            & > .treeview-item-inner:before {
                transform: rotate(0);
            }
        }
    }

    &.treeview-menu-box {
        .treeview-item-inner {
            border: none;
            padding-left: 33px;
            font-size: 14px;
            margin-bottom: 0;
            padding-top: 0;
            padding-bottom: 0;

            .second-row, .first-row {
                display: block;
            }
        }

        .treeview-item {

            .item-menu-container {
                margin-bottom: auto;
            }

            &:not(.open) > .treeview-item {
            }

            &.has-children {

                & > .treeview-item-inner:before {
                    top: calc(50% - 10px);
                    margin-right: 13px;
                    transform: rotate(0);
                    width: 14px;
                    height: 14px;
                    border: 2px solid #6b6d80;
                    background-position: center;
                    background-size: 50%;
                    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 24.1.2, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 8 8' style='enable-background:new 0 0 8 8;' xml:space='preserve'%3E%3Cline style='fill:none;stroke:%236B6D80;stroke-width:0.9993;stroke-miterlimit:10;' x1='0' y1='4' x2='8' y2='4'/%3E%3Cline style='fill:none;stroke:%236B6D80;stroke-width:0.9993;stroke-miterlimit:10;' x1='4' y1='8' x2='4' y2='0'/%3E%3C/svg%3E%0A");
                }
            }

            .treeview-item-inner {

                &.current {
                }

                & > a {
                }

                &:hover {
                    & > .treeview-item-text {
                    }
                }
            }

            .treeview-item-text {
                flex-direction: column;
                padding: 10px 5px;
                margin-top: -4px;
                margin-bottom: 0;

                &.active {
                    background-color: #f9cfd8;
                }
            }

            .treeview-item {
            }



            .item-menu-container {
            }

            &.open {
                & > .treeview-item-inner:before {
                    transform: rotate(0);
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='8px' height='1px'%3E%3Cpath fill-rule='evenodd' fill='rgb(107, 109, 128)' d='M-0.000,1.000 L-0.000,0.000 L8.000,0.000 L8.000,1.000 L-0.000,1.000 Z'/%3E%3C/svg%3E");
                }
            }
        }
    }
}
