@font-face {
    font-family: 'Montserrat';
    src: url('Montserrat-SemiBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

/*@font-face {
    font-family: 'Montserrat';
    src: url('Montserrat-SemiBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}*/

@font-face {
    font-family: 'Montserrat';
    src: url('ofont.ru_Montserrat.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

