@import '_mixins.scss';

.question-navigation {
    display: flex;
    flex-direction: row;
    margin-bottom: 48px;
    flex-wrap: wrap;

    .question-navigation-item {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        font-family: Montserrat, sans-serif;
        color: $main-text-color;
        font-size: 14px;
        border: 1px solid $question-navigation-border;
        margin-bottom: 5px;

        &:not(:last-child) {
            margin-right: 5px;
        }

        font-weight: 400;
        cursor: pointer;

        .question-navigation-item-link {
            padding-top: 1px;
            color: inherit;
            text-decoration: none;
            user-select: none;
            display: flex;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-bottom: 3px;
        }



        &:hover,
        &.active {
            .question-navigation-item-link {
                background-color: $main-text-color;
                border-color: $main-text-color;
                color: #fff !important;
            }
        }

        &.question-navigation-passed {
            background-color: $tab-grey-border;
            border-color: $tab-grey-border;
        }

        &.question-navigation-marked {
            position: relative;

            &:before {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                width: 13px;
                height: 10px;
                background-repeat: no-repeat;
                background-position: top left;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='13px' height='10px'%3E%3Cpath fill-rule='evenodd' fill='rgb(226, 23, 67)' d='M0.000,0.000 L13.000,0.000 L13.000,10.000 L0.000,0.000 Z'/%3E%3C/svg%3E");
            }
        }
    }
}


.exam-answer-options {
    margin-bottom: 50px;

    .form-group {
        margin-right: 0 !important;
    }

    &.exam-answer-options-sequence {
        .question-option {
            min-height: 50px;
        }
        .sequence-number {
            width: 68px;
            height: 50px;
            border-radius: 10px;
            font-weight: bold;
            margin-right: 15px;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $exam-result-red;
        }

        input:checked + label {
            color: inherit;
            background-color: inherit;
        }
    }
}



.question-option {
    label {
        display: flex;
        align-items: center;
        width: 100%;
        @include border-type-1;
        border-color: $question-option-border;
        padding: 5px;
        font-family: Montserrat, sans-serif;
        font-size: 14px;
        color: $main-text-color;
        min-height: 50px;
        padding: 10px;
        cursor: pointer;
    }

    input:checked + label,
    input:checked + input[type="hidden"] + label {
        background-color: $main_blue;
        color: #fff;
    }

    input {
        display: none;
    }
}

.question-actions-container {
    display: flex;

    .buttons-container {
        margin-right: 70px;
    }

    .button-end-exam-container {
        margin-left: auto;
    }

    .mark-answer-container {
        display: flex;
        align-items: flex-end;
    }
}

.exam-info-board {
    padding-top: 42px;
    padding-left: 97px;
    width: 262px;
}

.exam-timer {
    background-color: $timer-background;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $main-text-color;
    font-weight: bold;
    font-size: 24px;
    width: 100%;
    padding: 20px;

    .timer-item {
        display: none;
        &:not(:last-child):after {
            content: ':';
        }
    }

    &.exam-timer-started {
        .timer-item {
            display: inline-flex;
        }
    }

    margin-bottom: 148px;
}

.exam-timer.local {
    margin-bottom: 10px;
    width: fit-content;
}

.timerContainer {
    display: flex;
}

.exam-video-container {
    width: 162px;
    height: 162px;
    border-radius: 50%;
    overflow: hidden;
    border: 2px solid $main_blue;

    .exam-video {
        height: 100%;
    }
}

.check-video-container {
    width: 260px;
    height: 260px;
    border-radius: 50%;
    overflow: hidden;
    position: absolute;
    top: 138px;
    left: 70px;

    .exam-video {
        height: 100%;
    }
}

.border-timer {
    --border: 5px solid $main_blue;
    --animation: changeBorders 7s linear;
}



.circle__box {
    width: 290px;
    height: 290px;
    margin: 50px auto;
    position: relative;
}

.circle_elem .circle__wrapper {
    width: 145px;
    height: 300px;
    position: absolute;
    top: 0;
    overflow: hidden;
}

.circle_elem .circle__wrapper--right {
    right: 0;
}

.circle_elem .circle__wrapper--left {
    left: 0;
}

.circle_elem .circle__whole {
    width: 290px;
    height: 290px;
    border: 20px solid transparent;
    border-radius: 50%;
    position: absolute;
    top: 0;
    transform: rotate(-135deg);
}

.circle_elem .circle__right {
    border-top: 20px solid $main_blue;
    border-right: 20px solid $main_blue;
    right: 1px;
    animation: circleRight 7s linear forwards;
}

.circle_elem .circle__left {
    border-bottom: 20px solid $main_blue;
    border-left: 20px solid $main_blue;
    left: 1px;
    animation: circleLeft 7s linear forwards;
}

@keyframes circleRight {
    0% {
        transform: rotate(-135deg);
    }

    50%, 100% {
        transform: rotate(45deg);
    }
}

@keyframes circleLeft {
    0%, 50% {
        transform: rotate(-135deg);
    }

    100% {
        -webkit-transform: rotate(45deg);
    }
}

.flex_container_spacebetween {
    display: flex;
    align-items: center;
    justify-content: space-between;
}



.identification-video-container {
    width: 260px;
    height: 195px;
    border-radius: 10px;
    background-color: $main-text-color;
    margin-right: 30px;

    video {
        border-radius: 10px;
    }
}

.exam-information {
    display: flex;
    flex-direction: column;

    .exam-results-title,
    .exam-results-title-2 {
        color: $exam-result-red;
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 25px;
    }

    .exam-results-title-2 {
        margin-bottom: 20px;
        font-family: Montserrat, sans-serif;
        color: inherit;
        font-weight: normal;
        font-size: 14px;

        .accent {
            font-size: 16px;
            font-weight: bold;
            color: $exam-result-red;
        }
    }

    .buttons-container {
        margin-top: auto;
    }

    &.exam-information-block {
        margin-bottom: 30px;

        .exam-information-item {
            &:not(:last-child) {
                margin-bottom: 20px;
            }
        }
    }

    .exam-information-item {
        font-size: 14px;
        font-family: Montserrat;
        color: $main-text-color;

        &:not(:last-child) {
            margin-bottom: 40px;
        }

        .highlight {
            font-weight: bold;
        }

        &.bigger {
            font-size: 16px;
        }

        .exam-information-item-time {
            margin-left: 5px;
        }
    }

    .exam-information-line {
        display: flex;
        flex-wrap: wrap;

        .exam-information-item {
            &:not(:last-child) {
                margin-right: 60px;
            }
        }
    }
}

.exam-information-item {
    .buttons-container {
        margin-top: 15px;
    }
}

.exam-result {
    width: 680px;
    margin: 0 auto;
    margin-top: 60px;
    background-color: #fff;
    padding: 65px;
    padding-left: 197px;
    background-repeat: no-repeat;
    background-position: left 40px top 96px;
    min-height: 330px;

    @include box-shadow-right;

    &.exam-success {
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='112px' height='112px' viewBox='0 0 112 112' style='enable-background:new 0 0 112 112;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bclip-path:url(%23SVGID_2_);fill:%231065AF;%7D%0A%3C/style%3E%3Cg%3E%3Cdefs%3E%3Crect id='SVGID_1_' width='112' height='112'/%3E%3C/defs%3E%3CclipPath id='SVGID_2_'%3E%3Cuse xlink:href='%23SVGID_1_' style='overflow:visible;'/%3E%3C/clipPath%3E%3Cpath class='st0' d='M56,112C25.1,112,0,86.9,0,56C0,25.1,25.1,0,56,0s56,25.1,56,56C112,86.9,86.9,112,56,112 M56,3 C26.8,3,3,26.8,3,56s23.8,53,53,53s53-23.8,53-53S85.2,3,56,3'/%3E%3Cpath class='st0' d='M38.7,80.3c-0.4,0-0.7-0.1-1-0.4L25.2,68.2c-0.6-0.6-0.6-1.5-0.1-2.1c0.6-0.6,1.5-0.6,2.1-0.1l11.4,10.7 l44.4-44.2c0.6-0.6,1.6-0.6,2.1,0c0.6,0.6,0.6,1.6,0,2.1L39.8,79.8C39.5,80.1,39.1,80.3,38.7,80.3'/%3E%3C/g%3E%3C/svg%3E%0A");
    }
}



.attestation-result-list-container {
    &.theory,
    &.red-block {
        .attestation-title-block {
            background-color: $exam-result-red;
        }
    }

    &.practice,
    &.blue-block{
        .attestation-title-block {
            background-color: $exam-result-blue;
        }
    }
}

.attestation-title-block,
.title-block {
    font-size: 16px;
    display: flex;
    min-width: 165px;
    height: 31px;
    justify-content: flex-start;
    align-items: center;
    color: #fff;
    text-align: left;
    padding: 5px 15px;
    margin-right: 35px;

    &.red {
        background-color: $exam-result-red;
    }

    &.blue {
        background-color: $exam-result-blue;
    }
}

.attestation-result-list-title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    margin-bottom: 15px;




    .attestation-result-exam-name {
        font-weight: bold;
        margin-right: 20px;
    }
}

.attestation-result-list-menu {
    font-size: 22px;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: -4px;
    margin-left:30px;
}

.info-line-list {
    display: flex;
    align-items: flex-end;


    .info-line-list-item {
        display: flex;
        align-items: flex-end;
        align-items: center;
        background-repeat: no-repeat;
        background-position: right center;
        padding-right: 20px;

        &.item-ok {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='12.5px' height='15.5px'%3E%3Cpath fill-rule='evenodd' stroke='rgb(226, 13, 60)' stroke-width='1px' stroke-linecap='butt' stroke-linejoin='miter' fill='none' d='M1.500,6.500 L4.500,12.500 L10.500,1.500 '/%3E%3C/svg%3E");
        }

        &:not(:last-child) {
            font-family: Montserrat, sans-serif;
            font-size: 14px;
            margin-right: 30px;
        }
    }
}

.attestation-list-info {
    display: flex;

    .buttons-container {
        margin-left: auto;
        margin-top: -22px;
    }

    margin-bottom: 15px;
}

.attestation-result-list-container {
    .items-list-container {
        .list-item {
            margin-bottom: 0;

            &:not(:last-child) {
                border-bottom: 0;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }

            &:not(:first-child) {
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }

            .list-item-footer {
                padding-bottom: 15px;
            }

            .exam-period-item {
                margin-right: 40px;

                .exam-period-item-time {
                    margin-left: 25px;
                }
            }

            ion-icon {
                display: inline-flex;
                vertical-align: middle;
            }

            .content-item {
                ion-icon {
                    margin-left: 20px;
                    font-size: 30px;
                    margin-top: -10px
                }
            }
        }
    }
}

.file-link-with-icon,
.file-download-with-icon,
.file-print-with-icon {
    color: $main-text-color;
    display: flex;
    height: 22px;
    font-family: Montserrat;
    padding-top: 5px;
    padding-left: 25px;
    background-position: left center;
    background-repeat: no-repeat;
    align-items: center;
}
.file-link-with-icon {
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='13.4px' height='16.5px' viewBox='0 0 13.4 16.5' style='enable-background:new 0 0 13.4 16.5;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bclip-path:url(%23SVGID_2_);fill:%231065AF;%7D%0A%3C/style%3E%3Cg%3E%3Cdefs%3E%3Crect id='SVGID_1_' y='0' width='13.4' height='16.5'/%3E%3C/defs%3E%3CclipPath id='SVGID_2_'%3E%3Cuse xlink:href='%23SVGID_1_' style='overflow:visible;'/%3E%3C/clipPath%3E%3Cpath class='st0' d='M0.3,16.5h12.8c0.2,0,0.3-0.1,0.3-0.3V3.7c0-0.1,0-0.2-0.1-0.2L9.7,0.1l0,0c0,0,0,0,0,0c0,0,0,0,0,0 c0,0-0.1,0-0.1,0H0.3C0.1,0,0,0.1,0,0.3v15.9C0,16.4,0.1,16.5,0.3,16.5 M9.8,0.9l2.6,2.5H9.8V0.9z M0.6,0.6h8.7v3.2 C9.3,3.9,9.4,4,9.5,4h3.3v11.9H0.6V0.6z'/%3E%3C/g%3E%3C/svg%3E%0A");
}
.file-download-with-icon {
    background-size: 18px auto;
    padding-left: 30px;
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='20.4px' height='20.7px' viewBox='0 0 20.4 20.7' style='enable-background:new 0 0 20.4 20.7;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bclip-path:url(%23SVGID_2_);fill:%23E20D3C;%7D%0A%3C/style%3E%3Cg%3E%3Cdefs%3E%3Crect id='SVGID_1_' y='0' width='20.4' height='20.7'/%3E%3C/defs%3E%3CclipPath id='SVGID_2_'%3E%3Cuse xlink:href='%23SVGID_1_' style='overflow:visible;'/%3E%3C/clipPath%3E%3Cpath class='st0' d='M20.1,20.7H0.3c-0.2,0-0.3-0.1-0.3-0.3v-10c0-0.2,0.1-0.3,0.3-0.3c0.2,0,0.3,0.1,0.3,0.3v9.7h19.3v-9.7 c0-0.2,0.1-0.3,0.3-0.3c0.2,0,0.3,0.1,0.3,0.3v10C20.4,20.6,20.3,20.7,20.1,20.7'/%3E%3Cpath class='st0' d='M10.2,15.9c-0.2,0-0.3-0.1-0.3-0.3V0.3c0-0.2,0.1-0.3,0.3-0.3c0.2,0,0.3,0.1,0.3,0.3v15.3 C10.5,15.7,10.4,15.9,10.2,15.9'/%3E%3Cpath class='st0' d='M10.2,16.3L10.2,16.3c-0.1,0-0.2,0-0.2-0.1l-2.1-2.9c-0.1-0.1-0.1-0.3,0.1-0.4c0.1-0.1,0.3-0.1,0.4,0.1 l1.9,2.6l1.9-2.6c0.1-0.1,0.3-0.2,0.4-0.1c0.1,0.1,0.2,0.3,0.1,0.4l-2.1,2.9C10.4,16.3,10.3,16.3,10.2,16.3'/%3E%3C/g%3E%3C/svg%3E%0A");
}

.file-list {
    padding-top: 15px;
    margin-bottom: 45px;
}
.file-list-item {
    font-weight: 400;
    display: flex;
    align-items: flex-end;
    margin-bottom: 20px;
    font-weight: 300;
    .file-name {
        padding-left: 5px;
        width: 450px;
    }

    .file-link-with-icon {
        width: 225px;
    }
}

.video-container {
    width: 225px;
    height: 117px;
}

.ipcamtestinfo {
    padding: 10px;
    font-size: 20px;
}

.testvideo-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.testvideo-container-item-inner {
    padding: 5px;
}

.testvideo-container-item-inner [name="IpCamTitle"]{
    padding-bottom: 5px;
}

.testvideo-container-item {
    position: relative;
    max-width: 355px;
    border: 3px solid $light-grey;
    border-radius: 5px;
    margin-right: 1em;
    min-height: 355px;
}

.testvideo-container-item.error {
    border-color: $main-red;
}

.testvideo-container-item.success {
    border-color: $main_green;
}

.testvideo-container-item [name="IpCamData"] .error {
    color: $main-red;
}

.testvideo-container-item video {
    width: 340px;
    --height: auto;
    height: 191px;
}

.violation-frame-list {
    display: inline-flex;
    flex-wrap: wrap;

    .violation-frame-item {
        margin-right: 10px;
        margin-bottom: 5px;

        .violation-frame-link {
            display: block;
            width: 34px;
            height: 22px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: 100%;

            &.violation-type-0 {
                background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='33.2px' height='21.6px' viewBox='0 0 33.2 21.6' style='enable-background:new 0 0 33.2 21.6;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bclip-path:url(%23SVGID_2_);fill:%23B51035;%7D%0A%3C/style%3E%3Cg%3E%3Cdefs%3E%3Crect id='SVGID_1_' y='0' width='33.2' height='21.6'/%3E%3C/defs%3E%3CclipPath id='SVGID_2_'%3E%3Cuse xlink:href='%23SVGID_1_' style='overflow:visible;'/%3E%3C/clipPath%3E%3Cpath class='st0' d='M12.7,21.6H5.2c-2.9,0-5.2-2.3-5.2-5.2V5.2C0,2.3,2.3,0,5.2,0h7.5v0.9H5.2c-2.4,0-4.3,1.9-4.3,4.3v11.2 c0,2.3,1.9,4.3,4.3,4.3h7.5V21.6z'/%3E%3Cpath class='st0' d='M28,21.6h-7.5v-0.9H28c2.4,0,4.3-1.9,4.3-4.3V5.2c0-2.3-1.9-4.3-4.3-4.3h-7.5V0H28c2.9,0,5.2,2.3,5.2,5.2v11.2 C33.2,19.2,30.9,21.6,28,21.6'/%3E%3Cpath class='st0' d='M21.8,8.4c-0.9-2-2.9-3.3-5.2-3.3c-2.2,0-4.2,1.3-5.2,3.3L10.6,8c1.1-2.3,3.4-3.9,6-3.9c2.6,0,4.9,1.5,6,3.9 L21.8,8.4z'/%3E%3Cpath class='st0' d='M16.6,17.4c-2.6,0-4.9-1.5-6-3.9l0.9-0.4c0.9,2,2.9,3.3,5.2,3.3c2.2,0,4.2-1.3,5.2-3.3l0.9,0.4 C21.5,15.9,19.2,17.4,16.6,17.4'/%3E%3Cpath class='st0' d='M20.5,10.8c0,2.2-1.8,3.9-3.9,3.9c-2.2,0-3.9-1.8-3.9-3.9c0-2.2,1.8-3.9,3.9-3.9C18.8,6.8,20.5,8.6,20.5,10.8' /%3E%3C/g%3E%3C/svg%3E%0A");
            }

            &.violation-type-1 {
                background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='33.2px' height='21.6px' viewBox='0 0 33.2 21.6' style='enable-background:new 0 0 33.2 21.6;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bclip-path:url(%23SVGID_2_);fill:%23E20D3C;%7D%0A%3C/style%3E%3Cg%3E%3Cdefs%3E%3Crect id='SVGID_1_' y='0' width='33.2' height='21.6'/%3E%3C/defs%3E%3CclipPath id='SVGID_2_'%3E%3Cuse xlink:href='%23SVGID_1_' style='overflow:visible;'/%3E%3C/clipPath%3E%3Cpath class='st0' d='M12.7,21.6H5.2c-2.9,0-5.2-2.3-5.2-5.2V5.2C0,2.3,2.3,0,5.2,0h7.5v0.9H5.2c-2.4,0-4.3,1.9-4.3,4.3v11.2 c0,2.3,1.9,4.3,4.3,4.3h7.5V21.6z'/%3E%3Cpath class='st0' d='M28,21.6h-7.5v-0.9H28c2.4,0,4.3-1.9,4.3-4.3V5.2c0-2.3-1.9-4.3-4.3-4.3h-7.5V0H28c2.9,0,5.2,2.3,5.2,5.2v11.2 C33.2,19.2,30.9,21.6,28,21.6'/%3E%3Cpath class='st0' d='M21.8,8.4c-0.9-2-2.9-3.3-5.2-3.3c-2.2,0-4.2,1.3-5.2,3.3L10.6,8c1.1-2.3,3.4-3.9,6-3.9c2.6,0,4.9,1.5,6,3.9 L21.8,8.4z'/%3E%3Cpath class='st0' d='M16.6,17.4c-2.6,0-4.9-1.5-6-3.9l0.9-0.4c0.9,2,2.9,3.3,5.2,3.3c2.2,0,4.2-1.3,5.2-3.3l0.9,0.4 C21.5,15.9,19.2,17.4,16.6,17.4'/%3E%3Cpath class='st0' d='M20.5,10.8c0,2.2-1.8,3.9-3.9,3.9c-2.2,0-3.9-1.8-3.9-3.9c0-2.2,1.8-3.9,3.9-3.9C18.8,6.8,20.5,8.6,20.5,10.8' /%3E%3C/g%3E%3C/svg%3E%0A");
            }

            &.violation-type-2 {
                background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 24.1.2, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='33.2px' height='21.6px' viewBox='0 0 33.2 21.6' style='enable-background:new 0 0 33.2 21.6;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bclip-path:url(%23SVGID_2_);fill:%23E16534;%7D%0A%3C/style%3E%3Cg%3E%3Cdefs%3E%3Crect id='SVGID_1_' y='0' width='33.2' height='21.6'/%3E%3C/defs%3E%3CclipPath id='SVGID_2_'%3E%3Cuse xlink:href='%23SVGID_1_' style='overflow:visible;'/%3E%3C/clipPath%3E%3Cpath class='st0' d='M12.7,21.6H5.2c-2.9,0-5.2-2.3-5.2-5.2V5.2C0,2.3,2.3,0,5.2,0h7.5v0.9H5.2c-2.4,0-4.3,1.9-4.3,4.3v11.2 c0,2.3,1.9,4.3,4.3,4.3h7.5V21.6z'/%3E%3Cpath class='st0' d='M28,21.6h-7.5v-0.9H28c2.4,0,4.3-1.9,4.3-4.3V5.2c0-2.3-1.9-4.3-4.3-4.3h-7.5V0H28c2.9,0,5.2,2.3,5.2,5.2v11.2 C33.2,19.2,30.9,21.6,28,21.6'/%3E%3Cpath class='st0' d='M21.8,8.4c-0.9-2-2.9-3.3-5.2-3.3c-2.2,0-4.2,1.3-5.2,3.3L10.6,8c1.1-2.3,3.4-3.9,6-3.9c2.6,0,4.9,1.5,6,3.9 L21.8,8.4z'/%3E%3Cpath class='st0' d='M16.6,17.4c-2.6,0-4.9-1.5-6-3.9l0.9-0.4c0.9,2,2.9,3.3,5.2,3.3c2.2,0,4.2-1.3,5.2-3.3l0.9,0.4 C21.5,15.9,19.2,17.4,16.6,17.4'/%3E%3Cpath class='st0' d='M20.5,10.8c0,2.2-1.8,3.9-3.9,3.9c-2.2,0-3.9-1.8-3.9-3.9c0-2.2,1.8-3.9,3.9-3.9C18.8,6.8,20.5,8.6,20.5,10.8' /%3E%3C/g%3E%3C/svg%3E%0A");
            }

            &.violation-type-3 {
                background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 24.1.2, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='33.2px' height='21.6px' viewBox='0 0 33.2 21.6' style='enable-background:new 0 0 33.2 21.6;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bclip-path:url(%23SVGID_2_);fill:%23E9A63B;%7D%0A%3C/style%3E%3Cg%3E%3Cdefs%3E%3Crect id='SVGID_1_' y='0' width='33.2' height='21.6'/%3E%3C/defs%3E%3CclipPath id='SVGID_2_'%3E%3Cuse xlink:href='%23SVGID_1_' style='overflow:visible;'/%3E%3C/clipPath%3E%3Cpath class='st0' d='M12.7,21.6H5.2c-2.9,0-5.2-2.3-5.2-5.2V5.2C0,2.3,2.3,0,5.2,0h7.5v0.9H5.2c-2.4,0-4.3,1.9-4.3,4.3v11.2 c0,2.3,1.9,4.3,4.3,4.3h7.5V21.6z'/%3E%3Cpath class='st0' d='M28,21.6h-7.5v-0.9H28c2.4,0,4.3-1.9,4.3-4.3V5.2c0-2.3-1.9-4.3-4.3-4.3h-7.5V0H28c2.9,0,5.2,2.3,5.2,5.2v11.2 C33.2,19.2,30.9,21.6,28,21.6'/%3E%3Cpath class='st0' d='M21.8,8.4c-0.9-2-2.9-3.3-5.2-3.3c-2.2,0-4.2,1.3-5.2,3.3L10.6,8c1.1-2.3,3.4-3.9,6-3.9c2.6,0,4.9,1.5,6,3.9 L21.8,8.4z'/%3E%3Cpath class='st0' d='M16.6,17.4c-2.6,0-4.9-1.5-6-3.9l0.9-0.4c0.9,2,2.9,3.3,5.2,3.3c2.2,0,4.2-1.3,5.2-3.3l0.9,0.4 C21.5,15.9,19.2,17.4,16.6,17.4'/%3E%3Cpath class='st0' d='M20.5,10.8c0,2.2-1.8,3.9-3.9,3.9c-2.2,0-3.9-1.8-3.9-3.9c0-2.2,1.8-3.9,3.9-3.9C18.8,6.8,20.5,8.6,20.5,10.8' /%3E%3C/g%3E%3C/svg%3E%0A");
            }
        }
    }
}

.violation-img {
    max-width: 100%;
    width: 100%;
    max-height: 70vh;
}

.swal2-html-container {
    .video-container {
        width: 100%;
    }
}

.form-group .question-option {
    width: 100%;
}

.shuffle-questions-container {
    position: relative;
    font-size: 30px;

    ion-icon {
        position: absolute;
        bottom: -15px;
        left: 15px;
    }
}

.question-instructions {
    display: block;
    width: 100%;
    font-size: 18px;
    font-weight: normal;
    color: $main-red;
    margin-top: 15px;
}

.table-match {
    width: 100%;


    .table-match-header {
        display: flex;
        text-align: left;
        font-weight: bold;
        font-size: 14px;
        margin-bottom: 10px;
    }

    .match-container {
        text-align: left;
        justify-content: flex-start !important;
        margin-right: 35px !important;
        @extend .sequence-number;
        width: calc(100% - 35px) !important;
        height: 100% !important;
        padding: 10px;
    }

    .table-match-row {
        margin-bottom: 10px;
        display: flex;

        .table-match-column {
            text-align: left;
            width: 50%;
            display: flex;
            flex-direction: column;
            flex-grow: 1;

            .form-group {
                margin-bottom: 0;
                align-items: flex-start;
                display: flex;
                flex-direction: column;


                .question-option {
                    flex-grow: 1;
                    display: flex;
                    flex-direction: column;

                    .draggable-content {
                        flex-grow: 1;
                        display: flex;
                        flex-direction: column;

                        label {
                            align-items: flex-start;
                            flex-grow: 1;
                        }
                    }
                }
            }
        }
    }
}

/*label {
    &.gu-mirror {
        padding: 10px;
        border-radius: 5px;
        border: 1px solid $main-red;
    }
}*/

.gu-mirror {
    input[type="checkbox"] {
        display: none;
    }
    padding: 10px;
    border-radius: 5px;
    border: 1px solid $main-red;
}


.test-picture {
    cursor: pointer;
    max-width: 37.5%;
    max-height: 350px;
    border: 1px solid #393e5b
}

.form-group {
    .input-control {
        &.exam-textarea {
            display: flex;
            align-items: center;
            width: 100%;
            @include border-type-1;
            border-radius: 10px;
            border-color: $question-option-border;
            font-family: Montserrat, sans-serif;
            font-size: 14px;
            color: $main-text-color;
            min-height: 100px;
            padding: 15px 10px;
        }
    }
}

.answer-template-container {
    font-size: 14px;
    margin-bottom: 15px;
    line-height: 1.2;

    .template-title {
        margin-right: 50px;
    }

    .template-link {
        word-break: break-all;
        font-weight: bold;
        margin-right: 5px;
    }

    .template-delete {
        color: $main-text-color;
        text-decoration: underline;
        cursor:pointer;
    }
    .file-list {
        margin-bottom: 0;
    }
    .file-list-item {
        display: flex;
        width: 295px;
        margin-bottom: 5px;
        justify-content: space-between;
        color: $main-text-color;
    }
}