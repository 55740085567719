.tabs {
    display: flex;
    margin-bottom: 30px;
    padding-left: 15px;

    .tab-item {
        font-weight: normal;
        font-size: 16px;
        padding-bottom: 2px;
        border-bottom: 1px solid transparent;

        &:not(:last-child) {
            margin-right: 65px;
        }

        &.active {
            font-weight: bold;
            border-bottom: 1px solid $main-red;
        }
    }

    .tab-item-link {
        color: $text-grey;
        text-decoration: none;
    }
}

.tab-content {
    display: none;
    &.active {
        display: block;
    }
}

.application-tabs,
.personal-info-tabs {
    display: flex;
    height: 47px;
    align-items: stretch;
    margin-bottom: 40px;

    .tab-item {
        font-family: Montserrat, sans-serif;
        line-height: 1.143;
        font-size: 14px;
        min-width: 130px;
        padding: 5px;
        justify-content: center;
        align-items: center;
        background-color: $tab-grey;
        border: 1px solid $tab-grey-border;
        color: $main-text-color;

        &:not(:last-child) {
            border-right: none;
        }

        &.active {
            background-color: $main-red;
            color: #fff;

            .tab-item-link {
                color: #fff;
            }
        }

        .tab-item-link {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            text-align: center;
            text-decoration: none;
            color: inherit;
            color: $main-text-color;
        }
    }

    &.application-new {
        .tab-item-link.dis {
            pointer-events: none;
        }
    }

    &.users-info-tabs {
        height: auto;

        .tab-item {
            padding-bottom: 11px;
            width: auto;
            width: 196px;

            .tab-item-link {
                &.icon {
                    height: auto;
                    padding-top: 36px;
                    background-repeat: no-repeat;
                    background-position: center top 5px;
                    background-size: 34px auto;
                    font-size: 16px;

                    &.icon-users {
                        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 24.1.2, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='33.4px' height='26.3px' viewBox='0 0 33.4 26.3' style='enable-background:new 0 0 33.4 26.3;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bclip-path:url(%23SVGID_2_);fill:none;stroke:%233e405a;stroke-miterlimit:10;%7D%0A%3C/style%3E%3Cg%3E%3Cdefs%3E%3Crect id='SVGID_1_' y='0' width='33.4' height='26.3'/%3E%3C/defs%3E%3CclipPath id='SVGID_2_'%3E%3Cuse xlink:href='%23SVGID_1_' style='overflow:visible;'/%3E%3C/clipPath%3E%3Cpath class='st0' d='M21.1,20.9c1.6,0,2.9-1.5,2.9-2.9v-5.6c0-2.4-4.8-3.7-7.4-3.7s-7.4,1.2-7.4,3.7v5.6c0,1.4,1.4,2.9,2.9,2.9'/%3E%3Cpath class='st0' d='M29.9,22c1.4,0,2.9-0.8,2.9-2.2v-5.3c0-2.4-4.3-3.7-6.6-3.7c-0.2,0-0.5,0-0.7,0'/%3E%3Cline class='st0' x1='29.9' y1='26.3' x2='29.9' y2='16'/%3E%3Cline class='st0' x1='21.1' y1='26.3' x2='21.1' y2='13.8'/%3E%3Cline class='st0' x1='12.3' y1='13.8' x2='12.3' y2='26.3'/%3E%3Cpath class='st0' d='M3.4,22.2c-1.4,0-2.9-0.8-2.9-2.2v-5.5c0-2.4,4.3-3.7,6.6-3.7c0.2,0,0.5,0,0.7,0'/%3E%3Cline class='st0' x1='3.4' y1='26.3' x2='3.4' y2='16'/%3E%3Ccircle class='st0' cx='6.3' cy='5.3' r='3.2'/%3E%3Ccircle class='st0' cx='16.3' cy='3.7' r='3.2'/%3E%3Ccircle class='st0' cx='26.9' cy='5.6' r='3.2'/%3E%3C/g%3E%3C/svg%3E%0A");
                    }

                    &.icon-pretendents {
                        background-size: 31px auto;
                        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 24.1.2, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='39.4px' height='39.8px' viewBox='0 0 39.4 39.8' style='enable-background:new 0 0 39.4 39.8;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:none;stroke:%233E405A;stroke-miterlimit:10;%7D .st1%7Bclip-path:url(%23SVGID_2_);fill:none;stroke:%233E405A;stroke-miterlimit:10;%7D%0A%3C/style%3E%3Cpolyline class='st0' points='11.2,21.7 10,28.6 5.1,37.6 2.4,36.1 7,27.6 8.5,19.1 '/%3E%3Cg%3E%3Cdefs%3E%3Crect id='SVGID_1_' y='0' width='39.4' height='39.8'/%3E%3C/defs%3E%3CclipPath id='SVGID_2_'%3E%3Cuse xlink:href='%23SVGID_1_' style='overflow:visible;'/%3E%3C/clipPath%3E%3Cpolyline class='st1' points='10,10.6 5.1,13 3.6,19.5 0.6,18.8 2.4,10.8 11.2,6.6 '/%3E%3Cpath class='st1' d='M11.3,17.9c-0.2-0.4-0.2-0.8-0.1-1.2l1.6-4.8l2.5,5.3l7.7-3.7l-1.4-2.8L16.9,13l-2.5-5.2l-0.1,0l-2.9-1l-3,8.9 c-0.4,1.2-0.3,2.4,0.2,3.6c0.5,1.1,1.5,2,2.7,2.4l5.2,1.2l-0.8,6.5l3.1,0.4l1.1-9.3l-7.7-1.8C11.8,18.5,11.5,18.2,11.3,17.9z'/%3E%3Cpath class='st1' d='M17,2.8c0.6,1.8-0.4,3.6-2.2,4.2c-1.8,0.6-3.6-0.4-4.2-2.2c-0.6-1.8,0.4-3.6,2.2-4.2C14.6,0.1,16.4,1.1,17,2.8 z'/%3E%3Cpolyline class='st1' points='38.9,14.3 38.9,21.5 33.3,21.5 30.2,21.5 30.2,24.6 30.2,27.4 24.7,27.4 24.7,27.4 21.6,27.4 21.6,27.4 21.6,27.4 21.6,30.5 21.6,30.5 21.6,33.3 16,33.3 13,33.3 13,33.3 13,39.3 1.6,39.3 '/%3E%3C/g%3E%3C/svg%3E%0A");
                    }

                    &.icon-experts {
                        background-size: 13px auto;
                        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 24.1.2, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='10.5px' height='25.4px' viewBox='0 0 10.5 25.4' style='enable-background:new 0 0 10.5 25.4;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bclip-path:url(%23SVGID_2_);fill:none;stroke:%233E405A;stroke-miterlimit:10;%7D%0A%3C/style%3E%3Cg%3E%3Cdefs%3E%3Crect id='SVGID_1_' y='0' width='10.5' height='25.4'/%3E%3C/defs%3E%3CclipPath id='SVGID_2_'%3E%3Cuse xlink:href='%23SVGID_1_' style='overflow:visible;'/%3E%3C/clipPath%3E%3Cpath class='st0' d='M8.3,16.9c0.7,0,1.6-0.6,1.6-1.3V7.8c0-0.7-0.5-1.5-1.2-1.7C6.5,5.3,4,5.3,1.7,6.1C1,6.3,0.5,7.1,0.5,7.8v7.8 c0,0.7,1,1.3,1.6,1.3'/%3E%3Cline class='st0' x1='2.6' y1='8.2' x2='2.6' y2='24.9'/%3E%3Cline class='st0' x1='7.9' y1='8.2' x2='7.9' y2='24.9'/%3E%3Cline class='st0' x1='5.2' y1='16.5' x2='5.2' y2='24.9'/%3E%3Cline class='st0' x1='2.4' y1='24.2' x2='8.1' y2='24.2'/%3E%3Cpath class='st0' d='M7.8,3c0,1.4-1.1,2.5-2.5,2.5C3.8,5.5,2.7,4.4,2.7,3c0-1.4,1.1-2.5,2.5-2.5C6.6,0.5,7.8,1.6,7.8,3z'/%3E%3Cpolyline class='st0' points='2.6,8.2 2.6,24.9 7.9,24.9 7.9,8.2 '/%3E%3Cline class='st0' x1='5.2' y1='16.5' x2='5.2' y2='24.9'/%3E%3C/g%3E%3C/svg%3E%0A");
                    }

                    &.icon-employers {
                        background-size: 32px auto;
                        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='31.7px' height='28.5px' viewBox='0 0 31.7 28.5' style='enable-background:new 0 0 31.7 28.5;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bclip-path:url(%23SVGID_2_);fill:none;stroke:%233E405A;stroke-miterlimit:10;%7D%0A%3C/style%3E%3Cg%3E%3Cdefs%3E%3Crect id='SVGID_1_' width='31.7' height='28.5'/%3E%3C/defs%3E%3CclipPath id='SVGID_2_'%3E%3Cuse xlink:href='%23SVGID_1_' style='overflow:visible;'/%3E%3C/clipPath%3E%3Cpath class='st0' d='M27.5,3c0,1.4-1.1,2.5-2.5,2.5S22.6,4.3,22.6,3c0-1.4,1.1-2.5,2.5-2.5S27.5,1.6,27.5,3z'/%3E%3Cline class='st0' x1='22.4' y1='9.7' x2='22.4' y2='26'/%3E%3Cline class='st0' x1='27.7' y1='9.7' x2='27.7' y2='26'/%3E%3Cline class='st0' x1='25.1' y1='17.8' x2='25.1' y2='26'/%3E%3Cpath class='st0' d='M27.5,3c0,1.4-1.1,2.5-2.5,2.5S22.6,4.3,22.6,3c0-1.4,1.1-2.5,2.5-2.5S27.5,1.6,27.5,3z'/%3E%3Cpolyline class='st0' points='22.4,9.7 22.4,26 27.7,26 27.7,9.7 '/%3E%3Cline class='st0' x1='25.1' y1='17.8' x2='25.1' y2='26'/%3E%3Cpath class='st0' d='M28.1,18.2c0.7,0,1.6-0.6,1.6-1.3V9.2c0-0.7-0.5-1.5-1.2-1.7c-2.3-0.7-4.7-0.7-6.9,0c-0.7,0.2-1.2,1-1.2,1.7 v7.7c0,0.7,0.9,1.3,1.6,1.3'/%3E%3Cpolygon class='st0' points='12,25.6 15.8,25.6 15.8,13.1 18,13.1 13.9,9 9.8,13.1 12,13.1 '/%3E%3Cpolygon class='st0' points='3.6,25.6 6.8,25.6 6.8,20.5 8.7,20.5 5.2,17 1.7,20.5 3.6,20.5 '/%3E%3Cline class='st0' x1='0' y1='28' x2='31.7' y2='28'/%3E%3C/g%3E%3C/svg%3E%0A");
                    }
                }
            }

            &.active {
                .tab-item-link {
                    &.icon-users {
                        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 24.1.2, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='33.4px' height='26.3px' viewBox='0 0 33.4 26.3' style='enable-background:new 0 0 33.4 26.3;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bclip-path:url(%23SVGID_2_);fill:none;stroke:%23FFFFFF;stroke-miterlimit:10;%7D%0A%3C/style%3E%3Cg%3E%3Cdefs%3E%3Crect id='SVGID_1_' y='0' width='33.4' height='26.3'/%3E%3C/defs%3E%3CclipPath id='SVGID_2_'%3E%3Cuse xlink:href='%23SVGID_1_' style='overflow:visible;'/%3E%3C/clipPath%3E%3Cpath class='st0' d='M21.1,20.9c1.6,0,2.9-1.5,2.9-2.9v-5.6c0-2.4-4.8-3.7-7.4-3.7s-7.4,1.2-7.4,3.7v5.6c0,1.4,1.4,2.9,2.9,2.9'/%3E%3Cpath class='st0' d='M29.9,22c1.4,0,2.9-0.8,2.9-2.2v-5.3c0-2.4-4.3-3.7-6.6-3.7c-0.2,0-0.5,0-0.7,0'/%3E%3Cline class='st0' x1='29.9' y1='26.3' x2='29.9' y2='16'/%3E%3Cline class='st0' x1='21.1' y1='26.3' x2='21.1' y2='13.8'/%3E%3Cline class='st0' x1='12.3' y1='13.8' x2='12.3' y2='26.3'/%3E%3Cpath class='st0' d='M3.4,22.2c-1.4,0-2.9-0.8-2.9-2.2v-5.5c0-2.4,4.3-3.7,6.6-3.7c0.2,0,0.5,0,0.7,0'/%3E%3Cline class='st0' x1='3.4' y1='26.3' x2='3.4' y2='16'/%3E%3Ccircle class='st0' cx='6.3' cy='5.3' r='3.2'/%3E%3Ccircle class='st0' cx='16.3' cy='3.7' r='3.2'/%3E%3Ccircle class='st0' cx='26.9' cy='5.6' r='3.2'/%3E%3C/g%3E%3C/svg%3E%0A");
                    }

                    &.icon-pretendents {
                        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 24.1.2, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='39.4px' height='39.8px' viewBox='0 0 39.4 39.8' style='enable-background:new 0 0 39.4 39.8;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:none;stroke:%23FFFFFF;stroke-miterlimit:10;%7D .st1%7Bclip-path:url(%23SVGID_2_);fill:none;stroke:%23FFFFFF;stroke-miterlimit:10;%7D%0A%3C/style%3E%3Cpolyline class='st0' points='11.2,21.7 10,28.6 5.1,37.6 2.4,36.1 7,27.6 8.5,19.1 '/%3E%3Cg%3E%3Cdefs%3E%3Crect id='SVGID_1_' y='0' width='39.4' height='39.8'/%3E%3C/defs%3E%3CclipPath id='SVGID_2_'%3E%3Cuse xlink:href='%23SVGID_1_' style='overflow:visible;'/%3E%3C/clipPath%3E%3Cpolyline class='st1' points='10,10.6 5.1,13 3.6,19.5 0.6,18.8 2.4,10.8 11.2,6.6 '/%3E%3Cpath class='st1' d='M11.3,17.9c-0.2-0.4-0.2-0.8-0.1-1.2l1.6-4.8l2.5,5.3l7.7-3.7l-1.4-2.8L16.9,13l-2.5-5.2l-0.1,0l-2.9-1l-3,8.9 c-0.4,1.2-0.3,2.4,0.2,3.6c0.5,1.1,1.5,2,2.7,2.4l5.2,1.2l-0.8,6.5l3.1,0.4l1.1-9.3l-7.7-1.8C11.8,18.5,11.5,18.2,11.3,17.9z'/%3E%3Cpath class='st1' d='M17,2.8c0.6,1.8-0.4,3.6-2.2,4.2c-1.8,0.6-3.6-0.4-4.2-2.2c-0.6-1.8,0.4-3.6,2.2-4.2C14.6,0.1,16.4,1.1,17,2.8 z'/%3E%3Cpolyline class='st1' points='38.9,14.3 38.9,21.5 33.3,21.5 30.2,21.5 30.2,24.6 30.2,27.4 24.7,27.4 24.7,27.4 21.6,27.4 21.6,27.4 21.6,27.4 21.6,30.5 21.6,30.5 21.6,33.3 16,33.3 13,33.3 13,33.3 13,39.3 1.6,39.3 '/%3E%3C/g%3E%3C/svg%3E%0A");
                    }

                    &.icon-experts {
                        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 24.1.2, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='10.5px' height='25.4px' viewBox='0 0 10.5 25.4' style='enable-background:new 0 0 10.5 25.4;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bclip-path:url(%23SVGID_2_);fill:none;stroke:%23FFFFFF;stroke-miterlimit:10;%7D%0A%3C/style%3E%3Cg%3E%3Cdefs%3E%3Crect id='SVGID_1_' y='0' width='10.5' height='25.4'/%3E%3C/defs%3E%3CclipPath id='SVGID_2_'%3E%3Cuse xlink:href='%23SVGID_1_' style='overflow:visible;'/%3E%3C/clipPath%3E%3Cpath class='st0' d='M8.3,16.9c0.7,0,1.6-0.6,1.6-1.3V7.8c0-0.7-0.5-1.5-1.2-1.7C6.5,5.3,4,5.3,1.7,6.1C1,6.3,0.5,7.1,0.5,7.8v7.8 c0,0.7,1,1.3,1.6,1.3'/%3E%3Cline class='st0' x1='2.6' y1='8.2' x2='2.6' y2='24.9'/%3E%3Cline class='st0' x1='7.9' y1='8.2' x2='7.9' y2='24.9'/%3E%3Cline class='st0' x1='5.2' y1='16.5' x2='5.2' y2='24.9'/%3E%3Cline class='st0' x1='2.4' y1='24.2' x2='8.1' y2='24.2'/%3E%3Cpath class='st0' d='M7.8,3c0,1.4-1.1,2.5-2.5,2.5C3.8,5.5,2.7,4.4,2.7,3c0-1.4,1.1-2.5,2.5-2.5C6.6,0.5,7.8,1.6,7.8,3z'/%3E%3Cpolyline class='st0' points='2.6,8.2 2.6,24.9 7.9,24.9 7.9,8.2 '/%3E%3Cline class='st0' x1='5.2' y1='16.5' x2='5.2' y2='24.9'/%3E%3C/g%3E%3C/svg%3E%0A");
                    }

                    &.icon-employers {
                        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='31.7px' height='28.5px' viewBox='0 0 31.7 28.5' style='enable-background:new 0 0 31.7 28.5;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bclip-path:url(%23SVGID_2_);fill:none;stroke:%23FFFFFF;stroke-miterlimit:10;%7D%0A%3C/style%3E%3Cg%3E%3Cdefs%3E%3Crect id='SVGID_1_' width='31.7' height='28.5'/%3E%3C/defs%3E%3CclipPath id='SVGID_2_'%3E%3Cuse xlink:href='%23SVGID_1_' style='overflow:visible;'/%3E%3C/clipPath%3E%3Cpath class='st0' d='M27.5,3c0,1.4-1.1,2.5-2.5,2.5S22.6,4.3,22.6,3c0-1.4,1.1-2.5,2.5-2.5S27.5,1.6,27.5,3z'/%3E%3Cline class='st0' x1='22.4' y1='9.7' x2='22.4' y2='26'/%3E%3Cline class='st0' x1='27.7' y1='9.7' x2='27.7' y2='26'/%3E%3Cline class='st0' x1='25.1' y1='17.8' x2='25.1' y2='26'/%3E%3Cpath class='st0' d='M27.5,3c0,1.4-1.1,2.5-2.5,2.5S22.6,4.3,22.6,3c0-1.4,1.1-2.5,2.5-2.5S27.5,1.6,27.5,3z'/%3E%3Cpolyline class='st0' points='22.4,9.7 22.4,26 27.7,26 27.7,9.7 '/%3E%3Cline class='st0' x1='25.1' y1='17.8' x2='25.1' y2='26'/%3E%3Cpath class='st0' d='M28.1,18.2c0.7,0,1.6-0.6,1.6-1.3V9.2c0-0.7-0.5-1.5-1.2-1.7c-2.3-0.7-4.7-0.7-6.9,0c-0.7,0.2-1.2,1-1.2,1.7 v7.7c0,0.7,0.9,1.3,1.6,1.3'/%3E%3Cpolygon class='st0' points='12,25.6 15.8,25.6 15.8,13.1 18,13.1 13.9,9 9.8,13.1 12,13.1 '/%3E%3Cpolygon class='st0' points='3.6,25.6 6.8,25.6 6.8,20.5 8.7,20.5 5.2,17 1.7,20.5 3.6,20.5 '/%3E%3Cline class='st0' x1='0' y1='28' x2='31.7' y2='28'/%3E%3C/g%3E%3C/svg%3E%0A");
                    }
                }
            }
        }
    }
}



.line-tabs {
    display: flex;
    font-family: Railway, sans-serif;
    font-size: 16px;
    color: $main-text-color;
    margin-bottom: 40px;

    .tab-item {
        border-bottom: 2px solid $light-grey2;
        padding-bottom: 4px;

        &.active {
            border-color: $exam-result-red;
        }

        &:not(:first-child) {
            padding-left: 10px;
        }

        &:not(:last-child) {
            padding-right: 10px;
        }

        .tab-item-link {
            color: inherit;

            &:hover {
                text-decoration: none;
            }
        }
    }
}

.role-list-item {
    margin-bottom: 20px;
    font-size: 14px;
}

.role-block {
    padding-right: 15px;
    margin-bottom: 30px;
}